@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Source+Sans+3:wght@300;400;500&display=swap');


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.active {
  color: #00CEC9;
}

.app {
  display: flex;
  position: relative;
  overflow: hidden;
}

.avatar {
  border-radius: 50%;
}

.content {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */

::-webkit-scrollbar-track {
  background: #0c101b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #161616;
}

/* Handle on hover */

::-webkit-scrollbar-track:hover {
  background: #555;
}

.homepageLink {
  color: #00CEC9; 
}

.activeLink {
  color: #00CEC9; 
}

.greyLink {
  color: #c2c2c2;
}

#panel1bh-header, 
#panel2bh-header,
#panel3bh-header,
#panel4bh-header

 {
  background-color: #0c101b;
}

.lottieShip {
  width: 350px;
}

.lottieDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.loginForm {
  max-width: 500px;
  background-color: #0c101b;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
}

.registerForm {
  max-width: 650px;
  background-color: #0c101b;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
}
.audioUploadForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px, dashed #00CEC9;
  height: 150px;
  cursor: pointer;
  border-radius: 5px;
}

.image-upload-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px, dashed #00CEC9;
  height: 300px;
  cursor: pointer;
  border-radius: 5px;
}

.uploaded-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #1F2A40;

}

.upload-content {
  display: flex;
  align-items: center;
}

.settingsIcon {
  cursor: pointer;
}

.backBtnDiv {
  padding-bottom: 10px;
}